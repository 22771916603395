<template>
  <div class="lottery_activity">
    <div class="container">
      <basic :data="data" @set-data="handleSetData" class="basic" />
      <div class="previewContainer">
        <preview :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import basic from './__com__/basic'
import preview from './__com__/preview'

export default {
  components: {
    basic,
    preview
  },
  data() {
    return {
      data: {
        goodsData: [],
        dateRange: [],
        // 接口对应的字段
        name: '',
        activityEndTime: '',
        activityRule: '',
        activityStartTime: '',
        lotteryParticipateNum: 2,
        lotteryTime: '',
        lotteryWay: 2,
        productSkuId: '',
        productStock: 1,
        shareOverlay: 1,
        hideStatus: 0,
        useRule: '',
        virtualParticipateNum: ''
      }
    }
  },
  methods: {
    getDetail() {
      const loading = this.$loading()
      this.$api.lottery
        .lottery('detail', this.$route.query.id, {
          headers: {
            ['Bm-Area-Id']: this.$store.state.lottery.currentId
          }
        })
        .then(res => {
          this.data = res
          let { activityStartTime, activityEndTime, productInfo, productSkuId } = res
          this.$set(this.data, 'dateRange', [activityStartTime, activityEndTime])
          this.$set(this.data, 'goodsData', [
            {
              storeNum: productInfo.storeNum,
              id: productInfo.id,
              productName: productInfo.productName,
              productImageUrl: productInfo.productImageUrl,
              saleTimeStart: productInfo.saleTimeStart,
              saleTimeEnd: productInfo.saleTimeEnd,
              productDetailContent: productInfo.productDetailContent,
              skuData: {
                id: productSkuId,
                platformResidueStock: productInfo.platformResidueStock,
                originalPrice: productInfo.originalPrice
              }
            }
          ])
          loading.close()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    handleSetData(info) {
      this.data = info
      if (info.dateRange) {
        this.data.activityStartTime = info.dateRange[0]
        this.data.activityEndTime = info.dateRange[1]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail()
    }
  }
}
</script>

<style lang="less" scoped>
.lottery_activity {
  .container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .basic {
      flex-grow: 1;
    }
    .previewContainer {
      flex-basis: 450px;
      margin-top: -40px;
      position: -webkit-sticky;
      position: sticky;
      top: 10px;
      width: 450px;
      height: calc(100vh - 100px);
      margin-right: 10px;
      padding: 10px;
    }
  }
}
</style>
