<template>
  <div class="lottery_preview">
    <img src="@/asset/phone.png" class="bg" />
    <div class="panel">
      <div class="wrapper">
        <img :src="item.productImageUrl | thumbSmall" v-for="item in data.goodsData" :key="item.id" />
      </div>
      <div class="content">
        <div class="product-info">
          <div class="info">
            <div v-if="data.goodsData && data.goodsData.length" class="basic-info">
              <span class="value">价值</span>
              <span class="price">￥{{ data.goodsData[0].skuData.originalPrice }}</span>
            </div>
            <div v-else class="slot"></div>
            <span class="condition">{{ conditionText }}</span>
          </div>
          <div class="name" v-if="data.goodsData && data.goodsData.length">{{ data.goodsData[0].productName }}</div>
          <div class="desc">分享链接给好友，好友受邀参与霸王餐，可获得多个抽奖码</div>
          <div class="remain">
            <div class="time" v-show="data.lotteryWay === 1">
              <span class="d">06</span>
              <span class="t">天</span>
              <span class="d">23</span>
              <span class="t">时</span>
              <span class="d">23</span>
              <span class="t">分</span>
              <span class="d">06</span>
              <span class="t">秒</span>
            </div>
            <div class="time" v-show="data.lotteryWay === 2">
              <span>还剩2人</span>
            </div>
            <div>抽奖规则<i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="status">
            <div class="join">参与</div>
          </div>
          <div class="join-info">
            <div class="slot"></div>
            <div class="person">
              903人参与
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="lottery-flow">
            <div class="title">
              <span class="line"></span>
              <span class="text">抽奖流程</span>
            </div>
            <div class="flow">
              <div class="detail">
                <div class="item">
                  <i class="el-icon-user"></i>
                  <p>点击参与</p>
                </div>
                <div class="item">
                  <i class="el-icon-trophy"></i>
                  <p>等待开奖</p>
                </div>
                <div class="item">
                  <i class="el-icon-document-checked"></i>
                  <p>查看结果</p>
                </div>
                <div class="item">
                  <i class="el-icon-tickets"></i>
                  <p>使用权益</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="store-info">
          <div class="header">
            <div class="slot slot-right"></div>
            <div>TOAST HLL</div>
            <div class="btn">进店看看</div>
          </div>
          <el-divider></el-divider>
          <div class="store-list">
            <div class="item">
              <div class="info">
                <span class="name">广州天河店</span>
                <span class="tag">最近</span>
              </div>
              <div class="detail">
                <div>
                  <p><i class="el-icon-alarm-clock"></i>周一至周日 9:00-22:00</p>
                  <p><i class="el-icon-location-outline"></i>广州市天河区天河客运站</p>
                </div>
                <div class="loc">
                  <div class="item">
                    <i class="el-icon-position"></i>
                    <p>3.0km</p>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="item">
                    <i class="el-icon-phone-outline"></i>
                    <p>拨号</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="info">
                <span class="name">广州东圃店</span>
              </div>
              <div class="detail">
                <div>
                  <p><i class="el-icon-alarm-clock"></i>周一至周日 9:00-22:00</p>
                  <p><i class="el-icon-location-outline"></i>广州市天河区天河客运站</p>
                </div>
                <div class="loc">
                  <div class="item">
                    <i class="el-icon-position"></i>
                    <p>8.6km</p>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="item">
                    <i class="el-icon-phone-outline"></i>
                    <p>拨号</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="notice block" ref="activityRule">
          <div class="title">活动规则</div>
          <div style=" word-break: break-all;" v-html="data.activityRule"></div>
        </div>
        <div class="notice block" ref="useRule">
          <div class="title">使用须知</div>
          <div style=" word-break: break-all;" v-html="data.useRule"></div>
        </div>
        <div class="notice block">
          <div class="title">商品详情</div>
          <div
            v-if="data.goodsData && data.goodsData.length"
            v-html="data.goodsData[0].productDetailContent"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/util/filter'
let scrollOpt = {
  block: 'start',
  behavior: 'smooth'
}
export default {
  props: {
    data: Object
  },
  computed: {
    conditionText() {
      return this.data.lotteryWay === 1
        ? `${formatTime(this.data.lotteryTime)} 自动开奖`
        : `${this.data.lotteryParticipateNum}人 自动开奖`
    }
  },
  watch: {
    'data.activityRule'(nV, oV) {
      if (!oV) return
      scrollOpt.block = 'start'
      this.$refs.activityRule.scrollIntoView(scrollOpt)
    },
    'data.useRule'(nV, oV) {
      if (!oV) return
      scrollOpt.block = 'start'
      this.$refs.useRule.scrollIntoView(scrollOpt)
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.lottery_preview {
  position: relative;
  background: transparent;
  z-index: 999;
  height: fit-content;
  height: 864px;
  width: 400px;
  border-radius: 20px;
  overflow: hidden;
  user-select: none;
  transition: width 0.5s, height 0.5s, border-radius 0.8s;
  padding: 25px;
  scroll-behavior: smooth;
  .bg {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
  .topbar {
    position: absolute;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px 15px;
    .btn {
      float: right;
      cursor: pointer;
      font-size: 20px;
      &:hover {
        filter: brightness(70%);
      }
    }
  }
  .panel {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 30px;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    /deep/.el-carousel__container {
      height: 100% !important;
    }
  }

  .slot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f7f7f7;
    overflow: hidden;
  }
  .wrapper {
    height: 40%;
    width: 100%;
    overflow: hidden;
    position: sticky;
    top: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    position: relative;
    z-index: 9;
    background: #f5f5f5;
    border-radius: 20px;
    overflow: hidden;
    .product-info {
      background: #fff;
      padding: 20px;
      text-align: left;
      margin-bottom: 20px;
      .info {
        display: flex;
        align-items: center;
        .basic-info {
          display: flex;
          align-items: baseline;
          .price {
            font-size: 20px;
            color: #e7141a;
            font-weight: bold;
            margin-right: 5px;
          }
          .value,
          .condition {
            font-size: 10px;
            color: #999;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .condition {
          font-size: 10px;
          color: #999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: auto;
        }
        .sale {
          background: #f7f7f7;
          border-radius: 30px;
          width: 100px;
          text-align: center;
          color: #f9f9f9;
          padding: 3px;
        }
      }
      .name {
        font-size: 20px;
        color: #333;
        margin-top: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .desc {
        background: rgba(239, 239, 239, 1);
        font-size: 10px;
        color: #333333;
        margin: 10px 0;
        height: 30px;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 20px;
      }
      .remain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
        .d {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: #333333;
          color: #fff;
          margin-right: 2px;
        }
        .t {
          color: #333333;
        }
      }
      .status {
        margin: 10px 0;
        .join {
          width: 60px;
          height: 60px;
          margin: 0 auto;
          line-height: 60px;
          text-align: center;
          background: #e7141a;
          border-radius: 50%;
          color: #fff;
        }
      }
      .join-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 10px;
      }
      .lottery-flow {
        margin: 10px 0 0 0;
        width: 100%;
        .title {
          position: relative;
          height: 40px;
          margin-bottom: 20px;
          .line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            height: 1px;
            background: #d7d7d7;
          }
          .text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            background: #fff;
            padding: 4px;
          }
        }
        .flow {
          height: 40px;
          position: relative;
          width: 84%;
          margin: 0 auto;
          .line {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 1px;
            background: #d7d7d7;
          }
        }
        .detail {
          display: flex;
          font-size: 10px;
          color: #999999;
          .item {
            margin-right: 20px;
            text-align: center;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .store-info {
      background: #fff;
      padding: 20px;
      text-align: left;
      .header {
        display: flex;
        align-items: center;
        .slot-right {
          margin-right: 10px;
        }
        .btn {
          overflow: hidden;
          background: #f7f7f7;
          border-radius: 30px;
          padding: 7px 20px;
          font-size: 15px;
          margin-left: auto;
        }
      }
      .store-list {
        .item {
          margin-bottom: 10px;
          .info {
            margin-bottom: 10px;
            .name {
              color: #333333;
            }
            .tag {
              padding: 0 10px;
              background: rgba(253, 231, 232, 1);
              color: #e7141a;
              border-radius: 6px;
              font-size: 10px;
            }
          }
          .detail {
            display: flex;
            font-size: 10px;
            justify-content: space-between;
            color: #999999;
            .loc {
              display: flex;
              .item {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .shop-info {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
      .shop {
        display: flex;
        align-items: center;
        .logo {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #f7f7f7;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          flex: 1;
          font-size: 20px;
          color: #333;
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          overflow: hidden;
          background: #f7f7f7;
          color: transparent;
          border-radius: 30px;
          padding: 7px 20px;
          font-size: 15px;
        }
      }
    }
    .block {
      margin-top: 20px;
      background: #fff;
      padding: 20px;
      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
  /deep/img {
    max-width: 100% !important;
  }
}
</style>
