<template>
  <div class="lotteryBasic">
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>霸王餐设置</span>
      </div>

      <el-form ref="form" class="lotterySetting" :rules="rules" :model="form" label-width="200px">
        <el-form-item label="奖品信息" prop="goodsData">
          <el-button type="primary" @click="handleAddProduct" :disabled="isEditStatus || isViewStatus"
            >添加商品</el-button
          >
          <bm-tip>选择参与霸王餐的商品只支持设置平台全收部分，当商品多个SKU时，默认第一个SKU</bm-tip>

          <el-table :data="form.goodsData" style="margin-top:10px">
            <el-table-column align="left" prop="info" label="商品信息">
              <template slot-scope="scope">
                <div class="wrapper">
                  <img class="avatar" :src="scope.row.productImageUrl | thumbSmall" />
                  <span class="name">{{ scope.row.productName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="id" label="商品编号"> </el-table-column>
            <el-table-column align="center" prop="storeNum" label="商家门店"> </el-table-column>
            <el-table-column align="center" prop="originalPrice" label="价格">
              <template slot-scope="{ row }">
                <span class="value">￥{{ row.skuData.originalPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="currentStock" label="库存剩余">
              <template slot-scope="{ row }">
                <span class="value">{{ row.skuData.platformResidueStock }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template>
                <el-button
                  type="text"
                  size="small"
                  style="color: #e71414;"
                  @click="handleDel"
                  :disabled="isEditStatus || isViewStatus"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="奖品标题" prop="name">
          <el-input v-model="form.name" class="com-input" placeholder="请输入奖品标题" :disabled="isViewStatus" />
        </el-form-item>
        <!-- 奖品数量不能大于平台全收库存 -->
        <el-form-item label="奖品数量" prop="productStock" v-show="showStock">
          <el-input-number
            v-model="form.productStock"
            :min="1"
            :max="maxStock"
            class="el-input-number--small"
            controls-position="right"
            :disabled="isEditStatus || isViewStatus"
          />
        </el-form-item>
        <el-form-item label="开奖方式" prop="lotteryWay">
          <el-radio-group v-model="form.lotteryWay" :disabled="isEditStatus || isViewStatus" @change="handleSetWay">
            <el-radio :label="1">按时间</el-radio>
            <el-radio :label="2">按人数</el-radio>
            <el-radio :label="3">手动开奖</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开奖时间" prop="lotteryTime" v-show="form.lotteryWay === 1">
          <el-date-picker
            v-model="form.lotteryTime"
            value-format="timestamp"
            type="datetime"
            placeholder="选择日期时间"
            :picker-options="lotteryTimeOptions"
            :disabled="isEditStatus || isViewStatus"
          />
          <bm-tip>开奖时间要时间取整（小时）设置,例如2021-04-20 16:00:00</bm-tip>
        </el-form-item>
        <el-form-item label="人数" prop="lotteryParticipateNum" v-show="form.lotteryWay === 2">
          <el-input-number
            v-model="form.lotteryParticipateNum"
            :min="0"
            class="el-input-number--small"
            controls-position="right"
            :disabled="isEditStatus || isViewStatus"
          />
          <bm-tip>参与人数不能小于奖品数量</bm-tip>
        </el-form-item>
        <el-form-item label="活动时间" prop="dateRange" v-show="showTimeSelect">
          <el-date-picker
            v-model="form.dateRange"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled="isViewStatus"
            :picker-options="dateRangeOptions"
          />
          <bm-tip>默认是商品上下架时间，修改活动时间不会更改商品上下架时间，需要操作上下架请到商品管理中更改!</bm-tip>
        </el-form-item>
        <el-form-item label="是否支持裂变" prop="shareOverlay">
          <el-radio-group v-model="form.shareOverlay" :disabled="isViewStatus || isEditStatus">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <bm-tip>可设置抽奖概率规则，开启之后邀请好友可增加抽奖概率</bm-tip>
        </el-form-item>
        <el-form-item label="虚拟参与人数" prop="virtualParticipateNum">
          <el-input-number
            v-model="form.virtualParticipateNum"
            :min="0"
            class="el-input-number--small"
            controls-position="right"
            :disabled="isViewStatus"
            :max="maxPerson"
          />
          <bm-tip>
            <p>在原有参与人数的基础上增加的虚拟人数。</p>
            <p style="color: #e71414;">*注：建议在参与人大于或者等于100时修改</p>
          </bm-tip>
        </el-form-item>
        <el-form-item label="服务说明模板">
          <el-button-group>
            <el-button @click="pickNote(0)" plain type="warning" size="mini">清空</el-button>
            <el-button @click="pickNote(1)" plain type="success" size="mini">模板（广佛深）</el-button>
            <el-button @click="pickNote(2)" plain type="success" size="mini">模板（北上番禺等）</el-button>
          </el-button-group>
        </el-form-item>
        <el-form-item label="活动规则" prop="activityRule">
          <bm-tinymce :value="form.activityRule" @input="handleActivityRule($event)" :height="300"></bm-tinymce>
        </el-form-item>
        <el-form-item label="使用须知" prop="useRule">
          <bm-tinymce :value="form.useRule" @input="handleUseRule($event)" :height="300"></bm-tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="isViewStatus">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <productList :showList="showList" :selected-data="form.goodsData" @set-dialog-list="handleDialogList" />
  </div>
</template>

<script>
import productList from './productList'
import { getTemplate, getRule } from './template'
const vaildTime = {
  saleTimeStart: 0,
  saleTimeEnd: 0
}

export default {
  components: {
    productList
  },
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      rules: [],
      form: this.data,
      lotteryTimeOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      dateRangeOptions: {
        disabledDate(date) {
          return date && (date.valueOf() > vaildTime.saleTimeEnd || date.valueOf() < vaildTime.saleTimeStart)
        }
      },
      maxStock: 0,
      showTimeSelect: false,
      showStock: false,
      showList: false,
      toggleSign: false
    }
  },
  computed: {
    activityId() {
      return this.$route.query.id
    },
    isEditStatus() {
      // 当前是编辑状态并且是有人参与
      return this.$route.query.type === 'edit' && this.form.realParticipateNum > 0
    },
    isViewStatus() {
      return this.$route.query.type === 'view'
    },
    maxPerson() {
      if (this.form.lotteryWay === 2) {
        // 按人数
        return this.form.lotteryParticipateNum - 1
      } else {
        return Number.MAX_SAFE_INTEGER
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit('set-data', this.form)
      },
      deep: true
    },
    data: {
      handler(nV) {
        this.form = this.data
      }
    },
    'form.goodsData'(nV) {
      this.showTimeSelect = nV.length > 0
      this.showStock = nV.length > 0
      let data = nV[0]

      if (data) {
        vaildTime.saleTimeStart = data.saleTimeStart
        vaildTime.saleTimeEnd = data.saleTimeEnd
        // 必须是开团时间内
        if (this.toggleSign) {
          // 添加商品需要处理为可选时间
          this.form.activityStartTime = data.saleTimeStart + 1000
          this.form.activityEndTime = data.saleTimeEnd - 1000
          this.toggleSign = false
        }
        this.$set(this.form, 'dateRange', [this.form.activityStartTime, this.form.activityEndTime])

        this.maxStock = data.skuData.platformResidueStock
        if (this.form.productStock > data.skuData.platformResidueStock) {
          this.form.productStock = data.skuData.platformResidueStock
        }
      }
    }
  },
  methods: {
    pickNote(type) {
      this.form.activityRule = getTemplate(type)
    },
    handleSetWay(v) {
      if (v === 2 && this.form.virtualParticipateNum >= this.form.lotteryParticipateNum) {
        // 虚拟参与人数超过人数
        this.form.virtualParticipateNum = this.form.lotteryParticipateNum - 1
      }
    },
    save() {
      console.log(this.form)
      if (this.form.goodsData.length === 0) {
        this.$message.error('请选择商品!')
        return
      }
      this.$refs.form.validate((valid, validInfo) => {
        if (!valid) {
          this.$message.error(validInfo[Object.keys(validInfo)[0]][0].message)
          return
        }
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在操作，请稍候',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          const headers = {
            headers: {
              ['Bm-Area-Id']: this.$store.state.lottery.currentId
            }
          }
          const request = this.activityId
            ? this.$api.lottery.lottery('editLottery', this.form, headers)
            : this.$api.lottery.lottery('addLottery', this.form, headers)
          const text = this.activityId ? '编辑成功' : '新增成功'
          request
            .then(res => {
              this.$message.success(text)
              this.$router.push('/lottery/list')
            })
            .catch(err => {
              this.$message.error(err.msg)
            })
            .finally(() => {
              loading.close()
            })
        }
      })
    },
    handleDel() {
      this.form.goodsData = []
    },
    handleDialogList(bool, data) {
      this.showList = bool

      if (data && data.skuData.id) {
        this.form.goodsData = [data]
        this.form.productSkuId = data.skuData.id
        this.toggleSign = true
      }
    },
    handleAddProduct() {
      this.showList = true
    },
    getRules() {
      let that = this
      this.rules = this.$initRules([
        {
          label: '奖品标题',
          value: 'name',
          type: 'input',
          required: true
        },
        {
          label: '奖品数量',
          value: 'productStock',
          type: 'input',
          required: true,
          validator(rule, value, cb) {
            if (value === 0) {
              cb(new TypeError('奖品数量不能为0'))
              return
            } else {
              cb()
            }
          }
        },
        {
          label: '开奖方式',
          value: 'lotteryWay',
          type: 'select',
          required: true
        },
        {
          label: '开奖时间',
          value: 'lotteryTime',
          type: 'select',
          validator(rule, value, cb) {
            if (that.form.lotteryWay === 1) {
              if (!value) {
                cb(new TypeError('请选择开奖时间'))
                return
              }
              cb()
            }
            cb()
          }
        },
        {
          label: '人数',
          value: 'lotteryParticipateNum',
          type: 'input',
          validator(rule, value, cb) {
            if (that.form.lotteryWay === 2) {
              if (!value) {
                cb(new TypeError('请输入人数'))
                return
              }
              if (that.form.productStock) {
                if (value < that.form.productStock) {
                  cb(new TypeError('参与人数不能小于奖品数量'))
                  return
                }
              }
              cb()
            }
            cb()
          }
        },
        {
          label: '活动时间',
          value: 'dateRange',
          type: 'select',
          required: true
        },
        {
          label: '虚拟参与人数',
          value: 'virtualParticipateNum',
          type: 'select',
          validator(rule, value, cb) {
            if (that.form.lotteryWay === 2) {
              if (value >= that.form.lotteryParticipateNum) {
                cb(new TypeError('虚拟参与人数不能大于参与人数'))
                return
              }
              cb()
            }
            cb()
          }
        }
      ])
    },
    handleActivityRule(val) {
      this.form.activityRule = val
    },
    handleUseRule(val) {
      this.form.useRule = val
    }
  },
  created() {
    this.getRules()
  },
  mounted() {
    this.form = this.data
    this.form.activityRule = getTemplate(0)
    this.form.useRule = getRule()
  }
}
</script>

<style lang="less" scoped>
.lotteryBasic {
  .el-input-number--small {
    width: 130px;
  }
  .productSkuList:not(:last-child) {
    margin-bottom: 10px;
  }
  .info {
    margin-right: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 10px;
    }
    .name {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .com-input {
    width: 220px;
  }
}
</style>
