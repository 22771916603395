<template>
  <el-dialog
    :close-on-click-modal="false"
    title="添加商品"
    :visible="showList"
    width="60%"
    class="productDialog"
    @close="handleCancel"
  >
    <el-row :gutter="20" class="query">
      <el-form ref="cacheFormBasic" label-width="10em" inline>
        <el-form-item label="商品一级分类">
          <el-select
            :value="productCategoryInfo.main"
            @input="updateProductCategoryInfo({ key: 'main', value: $event })"
          >
            <el-option
              v-for="(i, n) in productMainCategoryList"
              :key="n"
              :label="i.categoryName"
              :value="i.id"
              placeholder="请选择一级分类"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="productCategoryInfo.main" label="商品二级分类">
          <el-cascader
            :value="productCategoryInfo.sub"
            @input="updateProductCategoryInfo({ key: 'sub', value: $event })"
            :options="productSubCategoryList"
            :props="{
              multiple: true,
              value: 'id',
              label: 'categoryName',
              children: 'childList'
            }"
            placeholder="请选择二级/三级分类"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input
            class="searchInput"
            placeholder="请输入商品名称"
            v-model="filter.productName"
            clearable
            @keyup.enter.native="getData"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <el-col :span="6">
        <el-button type="info" @click="resetSearch">重置</el-button>
        <el-button type="primary" @click="getData"> 搜索</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div class="tableContainer">
      <el-table :data="list" v-loading="loading" style="width: 100%" height="300">
        <el-table-column align="left" prop="info" label="商品信息">
          <template slot-scope="scope">
            <div class="wrapper">
              <img class="avatar" :src="scope.row.productImageUrl | thumbSmall" />
              <span class="name">{{ scope.row.productName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="商品编号"> </el-table-column>
        <el-table-column align="center" prop="storeNum" label="商家门店"> </el-table-column>
        <el-table-column align="left" prop="purePhoneNumber" label="sku信息" min-width="200">
          <template slot-scope="scope">
            <div v-for="(i, index) in scope.row.skuList" :key="i.id" class="skuList">
              <span class="info">
                <span class="label">名称：</span>
                <span class="value">{{ i.skuName }}</span>
              </span>
              <span class="info">
                <span class="label">库存剩余：</span>
                <span class="value">{{ i.platformResidueStock }}</span>
              </span>
              <span class="info">
                <span class="label">价格：</span>
                <span class="value">{{ i.originalPrice }}</span>
              </span>
              <el-button
                :type="currentParentId === scope.row.id && currentId === i.id ? 'success' : 'primary'"
                size="mini"
                style="float: right"
                @click="handleSelect(scope.row, i.id, index)"
                :disabled="i.platformResidueStock === 0"
              >
                {{ currentParentId === scope.row.id && currentId === i.id ? '已选' : '选择' }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          background
          :layout="pageLayout"
          :total="page.total"
          :page-sizes="sizeList"
          :page-size="page.size"
          :current-page="page.page"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button round @click="handleCancel">取消</el-button>
      <el-button round type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import tableMixin from '@/mixin/table'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  mixins: [tableMixin],
  props: {
    showList: {
      type: Boolean
    },
    selectedData: {
      type: Array
    }
  },
  data() {
    return {
      currentSelectedData: {},
      currentParentId: 0,
      currentId: 0,
      filter: {
        productCategoryIdList: [],
        productName: '',
        switchPublish: 1
      }
    }
  },
  computed: {
    ...mapGetters('product/partner', [
      'businessInfo',
      'storeList',
      'productImage',
      'productCategoryInfo',
      'allowUpdateAll'
    ]),
    ...mapGetters({
      productMainCategoryList: 'product/basic/productMainCategoryList',
      productCategoryList: 'product/basic/productCategoryList',
      bookingRuleList: 'product/basic/bookingRuleList'
    }),
    productSubCategoryList() {
      if (this.productCategoryInfo.main && this.productCategoryList.length) {
        const c = this.productCategoryList.find(i => i.id === this.productCategoryInfo.main)
        if (c) {
          return c.childList
        }
      }
    }
  },
  watch: {
    showList(nV) {
      if (nV) {
        this.currentParentId = 0
        this.currentId = 0
        this.currentSelectedData = {}
        let data = this.selectedData
        if (data.length > 0) {
          let parentId = data[0].id
          let curId = data[0].skuData.id

          this.list.forEach(item => {
            item.skuList.forEach(i => {
              if (item.id === parentId && i.id === curId) {
                this.currentParentId = parentId
                this.currentId = curId
              }
            })
          })
          this.currentSelectedData = data[0]
        }
      }
    }
  },
  methods: {
    ...mapMutations('product/partner', [
      'toggleStore',
      'updateStoreBookingRule',
      'updateProductCategoryInfo',
      'updateProductImage',
      'deleteSku'
    ]),
    ...mapActions({
      getProductCategory: 'product/basic/getProductCategory'
    }),
    resetSearch() {
      this.filter = {
        productCategoryIdList: [],
        productName: '',
        switchPublish: 1
      }
      this.page = {
        page: 1,
        size: 10
      }
      this.productCategoryInfo.main = ''
      this.productCategoryInfo.sub = []
      this.getData()
    },
    handleSelect(data, id, index) {
      this.currentParentId = data.id
      this.currentId = id
      data.skuData = data.skuList[index]
      this.currentSelectedData = data
    },
    handleCancel() {
      this.$emit('set-dialog-list', false)
    },
    handleConfirm() {
      if (this.currentId) {
        this.$emit('set-dialog-list', false, this.currentSelectedData)
      } else {
        this.$message.error('请选择数据')
        this.$emit('set-dialog-list', true)
      }
    },
    getList() {
      let ids = []
      if (this.productCategoryInfo.main && this.productCategoryInfo.sub.length > 0) {
        ids = Array.from(
          new Set(
            [this.productCategoryInfo.main].concat(
              this.productCategoryInfo.sub.reduce((total = [], a) => {
                return total.concat(a)
              })
            )
          )
        )
      }
      return this.$http.get('/boom-center-product-service/sysAdmin/product/findPageInPlatformStock', {
        params: {
          ...this.filter,
          ...this.page,
          type: 1,
          checkCodeSource: 1,
          secKill: 0,
          reservation: 0,
          productCategoryIdList: ids.join()
        },
        headers: {
          ['Bm-Area-Id']: this.$store.state.lottery.currentId
        }
      })
    }
  },
  created() {
    this.getProductCategory()
  }
}
</script>

<style lang="less" scoped>
.productDialog {
  .wrapper {
    display: flex;
    align-items: center;
    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 10px;
    }
    .name {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .skuList:not(:last-child) {
    margin-bottom: 10px;
  }
  .info {
    margin-right: 10px;
  }
  .searchInput {
    width: 200px;
  }
}
</style>
